import React, { Component } from 'react'
import { ReactComponent as SubmitIcon } from '../../gfx/speech-bubble.svg'
import SendGAEvent from '../GoogleAnalytics'

import './forms.scss'

// put the url of your form endpoint here
const endPoint = 'https://m8fy94rpwf.execute-api.eu-west-1.amazonaws.com/production/v1/cta'


export default class ContactForm extends Component {
  state = {
    error: false,
    success: false,
    contact_consent: true,
    url: typeof window !== `undefined` ? window.location.href : '',
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleSubmit = e => {
    const form = e.target
    fetch(endPoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() =>
        this.setState({
          success: true,
          error: false,
        })
      )
      .catch(error => this.setState({ error: true }))

    SendGAEvent('Contactform', 'submitted', this.state.url)
    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleCheckboxChange = e => {
      this.setState({ [e.target.name]: e.target.checked })
  }

    handleRadioChange = e => {
      if (e.target.checked) {
        this.setState({ [e.target.name]: e.target.value })
      }
  }

  render() {
    return (
      <section className="pb-5">
        <h2 className="text-flashy">
          Have any questions for us? If you’d like a demo or if you have a specific project on your mind, we’d love to hear from you!
        </h2>
        {this.state.error && (
          <div className="c-form-error text-danger">
            Something went wrong trying to send your message.
          </div>
        )}
        {this.state.success ? (
          <div className="c-form-success h3">
            Thank you for contacting us, we'll get back to you shortly!
            <img height="1" width="1" style={{display:'none'}} alt="" src="https://px.ads.linkedin.com/collect/?pid=1121809&conversionId=994244&fmt=gif" />
          </div>
        ) : (
          <form
            className=""
            name="contact"
            method="POST"
            data-netlify={endPoint === '/'}
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="first_name">
                  Name *
                </label>
                <input
                  type="text"
                  required
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="first_name"
                  name="first_name"
                  onChange={this.handleChange}
                  placeholder="Jane"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="last_name">
                  Family Name *
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  required
                  id="last_name"
                  name="last_name"
                  onChange={this.handleChange}
                  placeholder="Smith"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label  htmlFor="email_address">
                  Email Address *
                </label>
                <input
                  type="email"
                  required
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="email_address"
                  name="email_address"
                  onChange={this.handleChange}
                  placeholder="jane@example.com"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label  htmlFor="phone_number">
                  Phone Number
                </label>
                <input
                  type="phone"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="phone_number"
                  name="phone_number"
                  onChange={this.handleChange}
                  placeholder="0032 32 12 34 54"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label htmlFor="company">
                  Company
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="company"
                  name="company"
                  onChange={this.handleChange}
                  placeholder="Your company"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label htmlFor="job_function">
                  Function
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="job_function"
                  name="job_function"
                  onChange={this.handleChange}
                  placeholder="Job role"
                />
              </div>
            </div>
             <div className="row">
              <div className="col-sm-12 mb-3">
                <label  htmlFor="question_type">
                  Please select a topic *
                </label>
                <br />

                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="question_type" id="rd_webinar"
                             value="Webinar" onChange={this.handleRadioChange} required/>
                          <label className="form-check-label toption" htmlFor="rd_webinar" >Webinar</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="question_type" id="rd_demo"
                             value="Demo" onChange={this.handleRadioChange}  required/>
                          <label className="form-check-label toption" htmlFor="rd_demo">Demo</label>
                  </div>
                  <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="question_type" id="rd_sales"
                             value="Sales"  onChange={this.handleRadioChange}  required/>
                          <label className="form-check-label toption" htmlFor="rd_sales">Sales</label>
                  </div>
                <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="question_type" id="rd_support"
                             value="Support"  onChange={this.handleRadioChange}  required/>
                          <label className="form-check-label toption" htmlFor="rd_support">Support</label>
                  </div>
                <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="question_type" id="rd_other"
                             value="Other" onChange={this.handleRadioChange}  required />
                          <label className="form-check-label toption" htmlFor="rd_other">Other</label>
                  </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label htmlFor="message">
                  What do you want to tell us?
                </label>
                <textarea
                  type="text"
                  rows="3"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  name="message"
                  onChange={this.handleChange}
                  id="message"
                  placeholder="Put in your message here, we’ll contact you as soon as possible."
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">

                <input
                  type="checkbox"
                  className="border-left-0 border-right-0 border-top-0 mr-1"
                  id="contact_consent"
                  name="contact_consent"
                  onChange={this.handleCheckboxChange}
                  defaultChecked={false}
                  required
                />
                  <label  htmlFor="contact_consent">
                  By submitting this form, you agree to occasionally receiving communications from DataStories. You can unsubscribe at any time time.
                </label>
              </div>
            </div>
            <footer className="d-flex mt-4">
              <button
                className="mx-auto c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center"
                type="submit"
              >
                <span className="c-button__label mr-2">Send</span>
                <SubmitIcon />
              </button>
            </footer>
          </form>
        )}
      </section>
    )
  }
}
