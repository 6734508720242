import React, { Component } from 'react'
import posed from 'react-pose'

import { ReactComponent as Closer } from '../../gfx/menu-closer.svg'

import { Subscribe } from 'unstated'

import AppContainer from '../AppContainer'

import { Link } from 'gatsby'

import ProductModalMenu from '../ProductModalMenu'

import './style.scss'

const Modal = posed.div({
  open: {
    scale: 1,
    opacity: 1,
  },
  closed: {
    scale: 0.75,
    opacity: 0,
  },
})

const menuItems = [
  { id: 'book_demo', title: 'Book a Demo', to: 'https://demo.datastories.com', external:true, class:'c-modal-nav-highlight-link'},
  { id: 'products', title: 'Products', to: '/products' },
  { id: 'services', title: 'Services', to: '/products/services' },
  { id: 'applications', title: 'Applications', to: '/applications' },
  { id: 'about', title: 'About us', to: '/about' },
 // { id: 'jobs', title: 'Jobs', to: '/jobs' },
  //{ id: 'innovation', title: 'Innovation', to: '/innovation' },
  //{ id: 'history', title: 'History', to: '/history' },
  { id: 'testimonials', title: 'Testimonials', to: '/testimonials/' },
//  { id: 'compare_products', title: 'Compare', to: '/compare_products' },
  { id: 'compare_plans', title: 'Compare Plans', to: '/compare_plans' },
  { id: 'learn', title: 'Use Cases', to: '/learn' },
  {
    id: 'blog',
    title: 'Blog',
    to: '/blog',
  },
  { id: 'contact', title: 'Contact', to: '/contact' },
]

const socialLinks = [
  {
    id: 'linkedin',
    title: 'LinkedIn',
    to: 'https://www.linkedin.com/company/datastories-international/',
  },
  {
    id: 'facebook',
    title: 'Facebook',
    to: 'https://www.facebook.com/DataStories-1379014808842016/',
  },
]

export default class NavModal extends Component {
  state = {
    hasMounted: false,
  }

  componentDidMount() {
    this.setState({ hasMounted: true })
  }

  render() {
    return !this.state.hasMounted ? (
      <></>
    ) : (
      <Subscribe to={[AppContainer]}>
        {appContainer => (
          <Modal
            pose={appContainer.state.menuIsOpen ? 'open' : 'closed'}
            className="c-modal-nav bg-white position-fixed w-100"
            style={{
              pointerEvents: appContainer.state.menuIsOpen ? 'all' : 'none',
            }}
          >
            <div className="c-modal-nav__inner p-2 py-sm-5 px-xl-5 d-flex flex-column">
              <div className="row">
                <div className="col-lg-3">
                  <h2 className="c-mini-title d-none d-sm-block ">
                    Quick Navigation
                  </h2>
                  <nav className="c-modal-nav-menu my-4">
                    <ul>
                      {menuItems.map(item => (
                        <li key={item.id}>
                          {item.openContact ? (
                            <button onClick={appContainer.openContactModal} className={ item.class?  item.class : '' }>
                              {item.title}
                            </button>
                          ) : item.external ? (
                            <a href={item.to} target="_blank" className={ item.class?  item.class : '' }>
                              {item.title}
                            </a>
                          ) : (
                            <Link to={item.to} className={ item.class?  item.class : '' }>{item.title}</Link>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
                <div className="col-lg-9 d-none d-sm-block">
                  <h2 className="c-mini-title ">Our Offering</h2>
                  <div className="row mt-4">
                    <ProductModalMenu />
                  </div>
                </div>
              </div>
              <footer className="c-modal-nav__footer mt-auto">
                <p>
                  <strong>Follow Us</strong>
                </p>
                <ul>
                  {socialLinks.map(item => (
                    <li key={item.id}>
                      <a href={item.to}>{item.title}</a>
                    </li>
                  ))}
                </ul>
                <p>
                  <strong>Email Us</strong>
                  <a href="mailto:info@datastories.com">info@datastories.com</a>
                  <span className="d-none d-sm-inline">
                    .&nbsp;&nbsp;&nbsp;
                  </span>
                  <span className="d-block d-sm-inline">
                    <strong>Call Us</strong>
                    <a href="tel:003214800414">+32 14 800 414</a>
                  </span>
                </p>
              </footer>
              <button
                className="c-modal-nav__closer"
                onClick={appContainer.closeMenu}
              >
                <Closer className="mb-2" />
              </button>
            </div>
          </Modal>
        )}
      </Subscribe>
    )
  }
}
