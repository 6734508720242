import React, { Component } from 'react'
import { ReactComponent as SubmitIcon } from '../../gfx/report.svg'
import SendGAEvent from '../GoogleAnalytics'

import './forms.scss'

// put the url of your form endpoint here
const endPoint = 'https://m8fy94rpwf.execute-api.eu-west-1.amazonaws.com/production/v1/report_access'

const gartnerReportURL = 'https://www.gartner.com/reprints/katya-vladislavleva-flex-reprints?id=1-1YTGQIPZ&ct=200420&st=sb'

export default class GartnerReportForm extends Component {
  state = {
    error: false,
    success: false,
    contact_consent: true,
    url: typeof window !== `undefined` ? window.location.href : '',
  }

  encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  handleSubmit = e => {

   const form = e.target
    fetch(endPoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': form.getAttribute('name'),
          'message': 'Visitor accessed the report at '+gartnerReportURL,
        ...this.state,
      }),
    })
      .then(() => {
              this.setState({
                  success: true,
                  error: false,
              })
          }
      )
      .catch(error => this.setState({ error: true }))
    SendGAEvent('Gartnerform', 'submitted', this.state.url)
    e.preventDefault()
  }

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  handleCheckboxChange = e => {
      this.setState({ [e.target.name]: e.target.checked })
  }

  render() {
    return (
      <section className="pb-5">

        {this.state.error && (
          <div className="c-form-error text-danger">
            Something went wrong trying to access the report. Check your internet connection and try again.
          </div>
        )}
        {this.state.success ? (
          <div className="c-form-success h3">
               <h2 className="text-flashy">
                    Thank you for requesting the report, you can access it below.
                </h2>
              <br />
              <br />
              <br />
              <p align="center">
                  <a
                className="mx-auto c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center"
                href={gartnerReportURL} target='_blank'
              >
                <span className="c-button__label mr-2">Read the report</span>
                <SubmitIcon />
              </a>
              </p>
            <img height="1" width="1" style={{display:'none'}} alt="" src="https://dc.ads.linkedin.com/collect/?pid=1121809&conversionId=881828&fmt=gif" />
          </div>
        ) : (
            <div>
           <h2 className="text-flashy">
             Thanks for your interest! Please, fill in the form below and we’ll forward you to the report.
            </h2>
          <form
            className=""
            name="contact"
            method="POST"
            data-netlify={endPoint === '/'}
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <div hidden>
              <label>
                Don’t fill this out:{' '}
                <input name="bot-field" onChange={this.handleChange} />
              </label>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="first_name">
                  Name *
                </label>
                <input
                  type="text"
                  required
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="first_name"
                  name="first_name"
                  onChange={this.handleChange}
                  placeholder="Jane"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="last_name">
                  Family Name *
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  required
                  id="last_name"
                  name="last_name"
                  onChange={this.handleChange}
                  placeholder="Smith"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="email_address">
                  Email Address *
                </label>
                <input
                  type="email"
                  required
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="email_address"
                  name="email_address"
                  onChange={this.handleChange}
                  placeholder="jane@example.com"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="phone_number">
                  Phone Number
                </label>
                <input
                  type="phone"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="phone_number"
                  name="phone_number"
                  onChange={this.handleChange}
                  placeholder="0032 32 12 34 54"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="company">
                  Company
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="company"
                  name="company"
                  onChange={this.handleChange}
                  placeholder="Your company"
                />
              </div>
              <div className="col-sm-6 mb-3">
                <label className="text-mid" htmlFor="job_function">
                  Function
                </label>
                <input
                  type="text"
                  className="w-100 border-left-0 border-right-0 border-top-0"
                  id="job_function"
                  name="job_function"
                  onChange={this.handleChange}
                  placeholder="Job role"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">

                <input
                  type="checkbox"
                  className="border-left-0 border-right-0 border-top-0 mr-1"
                  id="contact_consent"
                  name="contact_consent"
                  onChange={this.handleCheckboxChange}
                  defaultChecked={false}
                  required
                />
                  <label  htmlFor="contact_consent">
                  By submitting this form, you agree to occasionally receiving communications from DataStories. You can unsubscribe at any time time.
                </label>
              </div>
            </div>



            <footer className="d-flex mt-4">
              <button
                className="mx-auto c-button c-button--fill-green c-button--large text-uppercase d-inline-flex align-items-center"
                type="submit"
              >
                <span className="c-button__label mr-2">Read the report</span>
                <SubmitIcon />
              </button>
            </footer>
          </form>
            </div>
        )}
      </section>
    )
  }
}
