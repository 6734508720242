import React from 'react'
import { Provider } from 'unstated'
import { siteMetadata } from '../../../gatsby-config'
import Footer from '../Footer'
import SiteNavi from '../SiteNavi'
import NavModal from '../NavModal'
import ContactModal from '../ContactModal'
import GartnerModal from '../GartnerModal'
import ModalOverlay from '../ModalOverlay'
import { StaticQuery, graphql } from 'gatsby'

import 'typeface-poppins'
import './gatstrap.scss'

export default ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query layoutQuery {
        ...featuredJob
        ...productSubMenuItems
        ...productFooterMenu
      }
    `}
    render={data => {
      const {
        featuredJob,
        productSubMenuItems,
        productFooterSubMenuItems,
      } = data
      return (
        <Provider>
          <div className="position-relative w-100">
            <SiteNavi
              title={siteMetadata.title}
              productSubMenuItems={productSubMenuItems}
              location={location}
            />
            <NavModal />
            {children}
            <Footer job={featuredJob} products={productFooterSubMenuItems} />
            <ContactModal />
            <GartnerModal />
            <ModalOverlay />
          </div>
        </Provider>
      )
    }}
  />
)
