import React from 'react'
import { Subscribe } from 'unstated'
import AppContainer from '../AppContainer'

import './style.scss'

const ModalOverlay = () => {
  return (
    <Subscribe to={[AppContainer]}>
      {appContainer => (
        <div
          className="c-modal-overlay"
          onClick={appContainer.closeContactModal}
          style={{
            opacity: appContainer.state.modalOverlayIsOpen ? 1 : 0,
            zIndex: appContainer.state.contactModalIsOpen || appContainer.state.gartnerModalIsOpen ? 1000 : 500,
            pointerEvents: (appContainer.state.contactModalIsOpen || appContainer.state.gartnerModalIsOpen)
              ? 'all'
              : 'none',
          }}
        />
      )}
    </Subscribe>
  )
}

export default ModalOverlay
