import { Container } from 'unstated'
import SendGAEvent from '../GoogleAnalytics'


class AppContainer extends Container {
  state = {
    menuIsOpen: false,
    modalOverlayIsOpen: false,
    contactModalIsOpen: false,
    gartnerModalIsOpen:false,
  }

  showModalOverlay = () => {
    this.setState({ modalOverlayIsOpen: true })
  }

  hideModalOverlay = () => {
    this.setState(prevState => {
      if (!prevState.contactModalIsOpen && !prevState.gartnerModalIsOpen) return { modalOverlayIsOpen: false }
    })
  }

  openMenu = () => {
    this.setState({ menuIsOpen: true })
  }

  closeMenu = () => {
    this.setState({ menuIsOpen: false })
  }

  openContactModal = () => {
    this.setState({ modalOverlayIsOpen: true, contactModalIsOpen: true })
    SendGAEvent('Contactform', 'opened', typeof window !== `undefined` ? window.location.href : '')
  }

  closeContactModal = () => {
    this.setState({ modalOverlayIsOpen: false, contactModalIsOpen: false })
  }

    openGartnerModal = () => {
    this.setState({ modalOverlayIsOpen: true, gartnerModalIsOpen: true })
    SendGAEvent('Gartnerform', 'opened', typeof window !== `undefined` ? window.location.href : '')
  }

    closeGartnerModal = () => {
    this.setState({ modalOverlayIsOpen: false, gartnerModalIsOpen: false })
  }



}

export default AppContainer
