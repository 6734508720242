export default function SendGAEvent(eventCategory, eventAction, eventLabel, eventValue) {

    if(typeof window !== `undefined`) {
        if (window.ga) {
            window.ga('send', 'event', eventCategory, eventAction, eventLabel,eventValue );
        }
      }
}


