import React from 'react'
import { Link, graphql } from 'gatsby'

import PropTypes from 'prop-types'

import './style.scss'
import { Subscribe } from 'unstated'
import AppContainer from '../AppContainer'



const CTABanner = props => {
  const { data } = props
  const { title, subTitle, linkLabel, link } = data || props

  return (
    <Subscribe to={[AppContainer]}>
      {appContainer => (
        <section className="container py-0">
          <div className="c-cta-banner bg-secondary text-white px-2 py-3 p-md-4 text-center text-md-left">
            <div className="d-md-flex align-items-center">
              <h2 className="c-section-title text-white mb-0 mr-md-2">
                {title}
              </h2>
              {subTitle && (
                <div className="c-cta-banner__text mt-1">{subTitle}</div>
              )}
              {link == 'contact_form' ? (
                <button
                  onClick={appContainer.openContactModal}
                  className="c-button mt-2 ml-auto"
                >
                  {linkLabel}
                </button>
              ) : (link.startsWith('https://') || link.startsWith('http://')) ? ( <a href={link} className="c-button c-banner-button mt-2 ml-auto">
                  {linkLabel}
                </a>): (
                <Link to={link} className="c-button c-banner-button mt-2 ml-auto">
                  {linkLabel}
                </Link>
              )}
            </div>
          </div>
        </section>
      )}
    </Subscribe>
  )
}

export default CTABanner

CTABanner.defaultProps = {
  data: null,
  title: 'Request a demo',
  subTitle: 'start creating value with DataStories.',
  linkLabel: 'Book a demo',
  link: 'https://demo.datastories.com',
}

CTABanner.propTypes = {
  data: PropTypes.object,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  linkLabel: PropTypes.string,
  link: PropTypes.string,
}

export const CTABannerQuery = graphql`
  fragment ctaBanner on MarkdownRemark {
    frontmatter {
      cta_banner {
        title
        subTitle
        linkLabel
        link
      }
    }
  }
  fragment ctaBannerMDX on Mdx {
    frontmatter {
      cta_banner {
        title
        subTitle
        linkLabel
        link
      }
    }
  }
`
