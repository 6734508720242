import React, { useState } from 'react'
import { graphql } from 'gatsby'

const SubNav = ({ productSubMenuItems, appContainer }) => {
  const [activeItem, setActiveItem] = useState(null)
  return (
    <div className="c-sub-nav text-left">
      <div className="c-sub-nav__inner shadow">
        <ul className="c-subnav__list">
          {productSubMenuItems.edges.map(product => {
            const {
              node: {
                id,
                fields: { slug },
                frontmatter: { title, color, targetMarket, icon },
              },
            } = product

            return (
              <li
                key={id}
                className={`c-sub-nav-item ${
                  !activeItem || id === activeItem
                    ? 'is-active'
                    : 'is-not-active'
                }`}
                onMouseOver={() => {
                  setActiveItem(id)
                }}
                onMouseOut={() => {
                  setActiveItem(null)
                }}
              >
                <a
                  href={slug}
                  className="d-flex align-items-center w-100 justify-content-flex-start px-3 py-2"
                >
                  {icon && (
                    <div className="c-sub-nav-item__icon flex-grow-0 flex-shrink-0">
                      <img src={icon.publicURL} alt="" />
                    </div>
                  )}
                  <div className="c-sub-nav-item__text ml-2 flex-grow-1">
                    <span className="c-sub-nav-item__target text-mid">
                      {targetMarket}
                    </span>
                    <span
                      className="c-sub-nav-item__title"
                      style={{ color: color }}
                    >
                      {title}
                    </span>
                  </div>
                </a>
              </li>
            )
          })}
        </ul>
        <div className="c-sub-nav__footer p-3 bg-light text-mid d-flex align-items-center">
          <a
            href="/products"
            className="c-sub-nav__centerbutton c-button c-button--brand text-nowrap text-dark"
          >
            Products overview
          </a>
        </div>
      </div>
    </div>
  )
}

export default SubNav

export const ProductSubMenuQuery = graphql`
  fragment productSubMenuItems on Query {
    productSubMenuItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "product" } } }
      sort: { order: ASC, fields: [frontmatter___weight] }
      limit: 2
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            color
            targetMarket
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`
