import React, { Component } from 'react'

import { StaticQuery, graphql } from 'gatsby'
import { ReactComponent as MoreIcon } from '../../gfx/more-arrow.svg'

import { Link } from 'gatsby'

import '../ProductCard/style.scss'
export default class ProductModalMenu extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            products: allMarkdownRemark(
              sort: { order: ASC, fields: [frontmatter___weight] }
              limit: 3
              filter: { frontmatter: { type: { eq: "product" } } }
            ) {
              edges {
                node {
                  fields {
                    slug
                  }
                  id
                  frontmatter {
                    title
                    targetMarket
                    color
                    teaserImage {
                      childImageSharp {
                        original {
                          src
                        }
                      }
                      extension
                      publicURL
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <>
              {data.products.edges.map(({ node: product }) => {
                const {
                  id,
                  fields: { slug },
                  frontmatter: {
                    title,
                    targetMarket,
                    color,
                    teaserImage: {

                       extension,
                       publicURL,
                    },
                  },
                } = product
                return (
                  <div key={id} className="col-sm-4 d-flex">
                    <article
                      className="c-product-card   position-relative"
                      style={{ backgroundColor: color }}
                    >
                      <div className="px-3 py-4">
                        <MoreIcon className="mb-2" />
                        <div className="c-mini-title mb-2 text-white">
                          {targetMarket}
                        </div>
                        <h2 className="c-product-card__title text-white">
                          {title}
                        </h2>
                      </div>
                      <div className="c-product-card__image-wrap">
                        <img
                          src={publicURL}
                          alt={title}
                          className="c-product-card__image"
                        />
                      </div>
                      <Link to={slug} />
                    </article>
                  </div>
                )
              })}
            </>
          )
        }}
      />
    )
  }
}
