import React from 'react'
import posed from 'react-pose'

import { ReactComponent as Closer } from '../../gfx/menu-closer.svg'

import { Subscribe } from 'unstated'

import AppContainer from '../AppContainer'

import './style.scss'
import GartnerReportForm from '../Forms/GartnerReportForm'

const Modal = posed.div({
  open: {
    scaleX: 1,
    opacity: 1,
  },
  closed: {
    scaleX: 0,
    delay: 0,
  },
})

const FormWrapper = posed.div({
  open: {
    opacity: 1,
    x: 0,
    delay: 200,
  },
  closed: {
    opacity: 0,
    x: 30,
  },
})

class GartnerModal extends React.Component {
  state = { hasMounted: false }

  componentDidMount = () => {
    this.setState({ hasMounted: true })
  }

  render() {
    return !this.state.hasMounted ? (
      <></>
    ) : (
      <Subscribe to={[AppContainer]}>
        {appContainer => (
          <Modal
            pose={appContainer.state.gartnerModalIsOpen ? 'open' : 'closed'}
            className="c-side-modal-gartner bg-white position-fixed r-0 h-100 py-5 px-2 p-sm-5 d-flex flex-column"
            style={{
              pointerEvents: appContainer.state.gartnerModalIsOpen
                ? 'all'
                : 'none',
            }}
          >
            <button
              className="c-side-modal-gartner__closer"
              onClick={appContainer.closeGartnerModal}
            >
              <Closer />
            </button>
            <FormWrapper
              pose={appContainer.state.gartnerModalIsOpen ? 'open' : 'closed'}
            >
              <GartnerReportForm> </GartnerReportForm>
            </FormWrapper>
          </Modal>
        )}
      </Subscribe>
    )
  }
}

export default GartnerModal
