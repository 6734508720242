import React from 'react'
import { graphql } from 'gatsby'

import './style.scss'

const FeaturedJob = ({ job }) => {
  return (
    <section className="p-0 d-none d-md-block">
      <div className="c-mini-title">Jobs</div>
      <h2 className="c-section-title">Want to work with us?</h2>
      <article className="c-featured-job">
        <h3 className="c-featured-job__title">{job.frontmatter.title}</h3>
        <div className="c-featured-job__description">{job.excerpt}</div>
        <footer className="mt-2">
          <a className="c-read-more" href={job.fields.slug}>
            Read more
          </a>
        </footer>
      </article>
    </section>
  )
}

export default FeaturedJob

export const FeaturedJobQuery = graphql`
  fragment featuredJob on Query {
    featuredJob: markdownRemark(frontmatter: { type: { eq: "job" } }) {
      id
      excerpt
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
