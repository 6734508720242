const markDownPlugins = [
  {
    resolve: 'gatsby-remark-images',
    options: {
      maxWidth: 750,
      linkImagesToOriginal: false,
      wrapperStyle: 'margin-bottom: 1.0725rem;',
    },
  },
  'gatsby-remark-copy-linked-files',
  'gatsby-remark-smartypants',
]
const markdownOptions = {
  plugins: [...markDownPlugins],
}

module.exports = {
  siteMetadata: {
    title: 'DataStories a Partners in Performance Company',
    description:
      'DataStories makes sense from spreadsheet data. We apply different predictive analytics algorithms and workflows based on what you want to learn from your data.',
    author: 'DataStories Int.',
    siteUrl: 'https://datastories.com',
  },
  pathPrefix: '/',
  plugins: [
    {
      resolve: 'gatsby-source-filesystem',
      options: {
        path: `${__dirname}/src/data`,
        name: 'data',
      },
    },
    {
      resolve: 'gatsby-plugin-svgr',
      options: {
        svgoConfig: {
          plugins: {
            removeViewBox: false,
          },
        },
        // see https://github.com/smooth-code/svgr for a list of all options
      },
    },
    {
      resolve: 'gatsby-plugin-mdx',
      options: {
        gatsbyRemarkPlugins: [
          { resolve: 'gatsby-remark-copy-linked-files' },
          { resolve: 'gatsby-remark-unwrap-images' },
          {
            resolve: 'gatsby-remark-images',
            options: {
              maxWidth: 1035,
              linkImagesToOriginal: false,
              wrapperStyle: 'margin-bottom: 2rem;',
            },
          },
          {
            resolve: 'gatsby-remark-external-links',
            options: {
              target: '_blank',
              rel: 'nofollow noopener noreferrer',
            },
          },
        ],
      },
    },
    {
      resolve: 'gatsby-transformer-remark',
      options: markdownOptions,
    },
    'gatsby-plugin-catch-links',
    'gatsby-plugin-sharp',
    'gatsby-transformer-sharp',
    'gatsby-remark-copy-linked-files',
    'gatsby-transformer-yaml',
    'gatsby-plugin-catch-links',
    'gatsby-plugin-react-helmet',
    'gatsby-plugin-sass',
    {
      resolve: `gatsby-plugin-polyfill-io`,
      options: {
        features: [`IntersectionObserver`],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: 'DataStories',
        short_name: 'DataStories',
        start_url: '/',
        background_color: '#FFFFFF',
        theme_color: '#2B2439',
        display: 'minimal-ui',
        icon: 'src/favicon.png', // This path is relative to the root of the site.
      },
    },
    'gatsby-plugin-sitemap',
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-64609420-1',
        // Puts tracking script in the head instead of the body
        head: true,
        // Setting this parameter is optional
        anonymize: false,
        // Setting this parameter is also optional
        respectDNT: false,
        sampleRate: 100,
        siteSpeedSampleRate: 10,
        cookieDomain: 'datastories.com'
      },
    },
    {
      resolve: `gatsby-plugin-google-tagmanager`,
      options: {
        id: 'GTM-WHGCXQ5',
        // Include GTM in development.
        // Defaults to false meaning GTM will only be loaded in production.
        includeInDevelopment: false,
      },
    },
    {
      resolve: 'gatsby-plugin-intercom-spa',
      options: {
        app_id: 'hbtnuaag',
        include_in_development: true,
      },
    },
    'gatsby-plugin-smoothscroll',
     {
       resolve: 'gatsby-plugin-s3',
       options: {
         bucketName: 'new-website.datastories.com',
         protocol: 'https',
         hostname: 'datastories.com',
       },
     },
    'gatsby-plugin-meta-redirect', // make sure this is always the last one
  ],
}
