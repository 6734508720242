import React from 'react'
import { Link } from 'gatsby'
import { Subscribe } from 'unstated'
import Headroom from 'react-headroom'
import SubNav from './SubNav'
// import app icons

import AppContainer from '../AppContainer'
import logoWhite from '../../gfx/logo-white.svg'
import logoColor from '../../gfx/logo.svg'

import './style.scss'

import { ReactComponent as More } from '../../gfx/more.svg'

const menuLinks = [
  { id: 1, title: 'Products', link: '/products', hasSubNav: false },
  { id: 2, title: 'Use Cases', link: '/learn' },
  { id: 4, title: 'About us', link: '/about' },
  // { id: 5, title: 'Blog', link: '/blog' },
 // { id: 6, title: 'Jobs', link: '/jobs' },
  { id: 8, title: 'Contact', link: '/contact' },
]

class SiteNavi extends React.Component {
  state = {}
  componentDidMount = () => {
    const {
      location: { pathname },
    } = this.props
    const locationBasePath = pathname.split('/')[1]
    let darkMode = false
    let disableSticky = false;

    // for basepaths + subpaths that need the dark menu
    switch (locationBasePath) {
      case 'history':
      case 'compare_products':
      case 'compare_plans':
      case 'innovation':
      case 'products':
        darkMode = true
        break
    }

    // for basepaths that need the dark menu
    switch (pathname) {
      case '/applications':
      case '/applications/':
      case '/testimonials':
      case '/testimonials/':
      case '/learn':
      case '/learn/':
      case '/blog':
      case '/blog/':
        darkMode = true
        break;
      case '/compare_products':
      case '/compare_products/':
        disableSticky = true;
      break;
    }







    this.darkMode = darkMode
    this.setState({ darkMode, disableSticky })

  }

  render() {
    const {
      props: { productSubMenuItems },
    } = this

    return (
      <Subscribe to={[AppContainer]}>
        {appContainer => {
          const darkMode =
            !appContainer.state.modalOverlayIsOpen && this.state.darkMode
          return (
            <Headroom
              disable={this.state.disableSticky}
              disableInlineStyles
              onPin={() => {
                this.setState({ darkMode: true })
              }}
              onUnfix={() => {
                this.setState({ darkMode: this.darkMode })
              }}
              onUnpin={() => {
                this.setState({ darkMode: this.darkMode })
              }}
            >
              <nav
                className={`
            ${darkMode ? 'text-dark' : 'text-white'}
            navbar navbar-expand  flex-column flex-md-row position-relative w-100 py-3 ${
              appContainer.state.modalOverlayIsOpen ? 'overlay-is-open' : ''
            }`}
              >
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <Link to="/" className="c-logo">
                    {darkMode ? (
                      //  && !appContainer.state.modalOverlayIsOpen
                      <img src={logoColor} alt="Datastories" />
                    ) : (
                      <img src={logoWhite} alt="Datastories" />
                    )}
                  </Link>
                  <div className="d-none d-sm-flex align-items-center ml-2 text-nowrap">
                    <a href={"https://demo.datastories.com"}  target="_blank"
                      className="c-button c-button--brand c-button--inherit"
                    >
                     Book a demo
                    </a>
                    <a
                      href="https://platform.datastories.com/"
                      className={`c-button border-0 ml-1
                      
                      ${
                        darkMode
                          ? // && !appContainer.state.modalOverlayIsOpen
                            'text-dark'
                          : 'text-white'
                      }
            
                      `}
                    >
                      Log In
                    </a>
                  </div>
                  <div className="ml-auto d-flex align-items-center">
                    <ul className="navbar-nav flex-row ml-md-auto d-none d-lg-flex">
                      {menuLinks.map(item => (
                        <li
                          key={item.id}
                          className={`ml-3 nav-item ${this.state[
                            'hover' + item.id
                          ] && 'hover'}`}
                          onMouseOver={() => {
                            this.setState({ [`hover${item.id}`]: true })
                            item.hasSubNav && appContainer.showModalOverlay()
                          }}
                          onMouseLeave={() => {
                            this.setState({ [`hover${item.id}`]: false })
                            appContainer.hideModalOverlay()
                          }}
                          onClick={() => {
                            this.setState({ [`hover${item.id}`]: false })
                          }}
                        >
                          <Link
                            activeClassName="text-primary"
                            to={item.link}
                            onClick={appContainer.hideModalOverlay}
                          >
                            {item.title}
                          </Link>
                          {item.hasSubNav && (
                            <SubNav
                              appContainer={appContainer}
                              productSubMenuItems={productSubMenuItems}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                    <button
                      className="ml-3 c-menu-toggle"
                      onClick={appContainer.openMenu}
                    >
                      <More />
                    </button>
                  </div>
                </div>
              </nav>
            </Headroom>
          )
        }}
      </Subscribe>
    )
  }
}

export default SiteNavi
