import React from 'react'
import { graphql } from 'gatsby'
import FeaturedJob from '../FeaturedJob'
import CTABanner from '../CTABanner'

import { Link } from 'gatsby'
import { Subscribe } from 'unstated'

import './style.scss'
import AppContainer from '../AppContainer/index'

const FooterMenu = ({ menuItems, contactButton }) => {
  return (
    <Subscribe to={[AppContainer]}>
      {appContainer => (
        <nav className="c-footer-menu">
          <ul>
            {menuItems.map(menuItem => (
              <li key={menuItem.link + menuItem.label}>
                <Link to={menuItem.link}>{menuItem.label}</Link>
                {menuItem.subItems && (
                  <ul>
                    {menuItem.subItems.map(subMenuItem => (
                      <li key={subMenuItem.link + subMenuItem.label}>
                        <Link to={subMenuItem.link}>{subMenuItem.label}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
            {contactButton && (
              <li>
                <button onClick={appContainer.openContactModal}>
                  Contact Us
                </button>
              </li>
            )}
          </ul>
        </nav>
      )}
    </Subscribe>
  )
}

const Footer = ({ job, products }) => {
  const today = new Date()

  const mainMenuLinks = [
      { label: 'About Partners in Performance', link: 'https://www.pip.global/' },
      { label: 'Use Cases', link: '/learn' },
       { label: 'Services', link: '/products/services' },

    {
      label: 'Products',
      link: '/products',
      subItems: products.edges.map(product => ({
        label: product.node.frontmatter.title,
        link: product.node.fields.slug,
      })),
    },



  ]

  const secondaryMenuLinks = [
    { label: 'About Us', link: '/about' },
   // { label: 'Jobs', link: '/jobs' },
    { label: 'Contact us', link: '/contact' },
    //    { label: 'Partners', link: '#' },

    //    { label: 'Faq', link: '#' },
    { label: 'Testimonials', link: '/testimonials/' },
    { label: 'Blog', link: '/blog' },
      { label: 'Scientific publications', link: '/papers' },
        { label: 'Applications', link: '/applications' },
  ]
//             <FeaturedJob job={job} />
  return (
    <footer className="c-footer bg-light ">
      <div className="container py-3 py-md-5">
        <div className="row d-block d-md-flex">
          <div className="col-md-3 mb-3">
            <FooterMenu
              products={products}
              menuItems={mainMenuLinks}
              // contactButton
            />
          </div>
          <div className="col-md-3">
            <FooterMenu menuItems={secondaryMenuLinks} />
          </div>
          <div className="col-md-6 pl-5 d-none d-sm-block">
          <div className="newsletter">
              <h2>Newsletter</h2>
              <form
                  action="//datastories.us4.list-manage.com/subscribe/post?u=25f30c53359e8c329e3f038db&amp;id=661ebc80c5"
                  method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate"
                  target="_blank" noValidate="">
                <div id="mc_embed_signup_scroll ">
                  <div className="mc-field-group  py-1">
                    <input type="text" placeholder="Name" value="" name="FNAME" className="w-75 border-left-0 border-right-0 border-top-0" id="mce-FNAME"
                           style={{backgroundColor:"transparent"}} />
                  </div>
                  <div className="mc-field-group py-1">
                    <input type="email" placeholder="Email" value="" name="EMAIL" className="w-75 border-left-0 border-right-0 border-top-0"
                           id="mce-EMAIL" style={{backgroundColor:"transparent"}} />
                  </div>
                  <div id="mce-responses" className="clear py-1">
                    <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                    <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                  </div>
                  <div style={{position: "absolute", left: "-5000px"}}><input type="text"
                                                                         name="b_25f30c53359e8c329e3f038db_661ebc80c5"
                                                                         tabIndex="-1" value="" /></div>
                  <div className="clear"><input type="submit" value="Subscribe" name="subscribe"
                                                id="mc-embedded-subscribe" className="mx-auto c-button c-button--fill-green  text-uppercase d-inline-flex align-items-center" /></div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-12">
            <div className="c-disclaimer">
              <ul>
                <li> © {today.getFullYear()} DataStories a Partners in Performance company</li>
                <li>
                  <a href="/privacy_policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

export const ProductFooterQuery = graphql`
  fragment productFooterMenu on Query {
    productFooterSubMenuItems: allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "product" }, hideInMenu: { eq: false } }
      }
      sort: { order: ASC, fields: [frontmatter___weight] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
